<template>
  <v-col class="px-0 py-0">
    <v-row no-gutters align="center" style="margin-top: 12px" v-if="isEdit">
      <div
        class="iconBackground"
        style="margin-right: 20px"
        @click="
          $router.push({
            name: 'pricesEditComponent',
            params: { route_id: route?.id },
          })
        "
      >
        <div class="priceIcon" />
      </div>
      <div class="iconBackground">
        <div class="ticketIcon" />
      </div>
    </v-row>
    <div class="sectionBackground">
      <p class="sectionTitle">Основні дані маршруту</p>
      <div class="sectionLine" />
      <v-row no-gutters align="center">
        <div
          class="imgBackground"
          :style="route?.image?.img ? 'padding: 0px' : ''"
          :key="key"
          @click="showImageGallery = true"
        >
          <img
            v-if="route?.image?.path"
            :src="route?.image?.path"
            alt="Selected Image"
            width="100%"
            height="120px"
            style="border-radius: 10px; object-fit: cover"
          />
          <v-col class="px-0 py-0" style="text-align: center" cols="8" v-else>
            <img src="@/assets/img/iconsSvg/imageIcon.svg" alt="image" />
            <p style="margin: 0px">Завантажити фото</p>
          </v-col>
        </div>
        <v-col class="px-0 py-0" style="margin-left: 20px">
          <v-autocomplete
            outlined
            dense
            placeholder="Введіть тут.."
            label="Пункт відправлення (звідки)"
            hide-details
            v-model="route.departure"
            style="border-radius: 10px"
            color="#E2E2E2"
            background-color="#FFF"
            height="48px"
            :error-messages="departureError"
            :items="Object.values(filteredFromCities)"
            :item-text="
              (value) =>
                `${
                  value.translations.find((translate) => translate.lang == 'ua')
                    .name
                }`
            "
            :item-value="'id'"
          />
          <v-text-field
            outlined
            dense
            placeholder="Введіть тут.."
            label="Загальна відстань"
            hide-details
            v-model="route.total_distance"
            style="border-radius: 10px; margin-top: 20px"
            color="#E2E2E2"
            background-color="#FFF"
            height="48px"
            type="number"
            min="0"
            hide-spin-buttons
            @keypress="isNumber"
            :error-messages="totalDistanceError"
          />
        </v-col>
        <v-col class="px-0 py-0" style="margin-left: 20px">
          <v-autocomplete
            outlined
            dense
            placeholder="Введіть тут.."
            label="Пункт призначення (куди)"
            hide-details
            v-model="route.destination"
            style="border-radius: 10px"
            color="#E2E2E2"
            background-color="#FFF"
            height="48px"
            :error-messages="desctinationError"
            :items="Object.values(filteredToCities)"
            :item-text="
              (value) =>
                `${
                  value.translations.find((translate) => translate.lang == 'ua')
                    .name
                }`
            "
            :item-value="'id'"
          />
          <v-text-field
            outlined
            dense
            placeholder="Введіть тут.."
            label="Приблизний час поїздки"
            hide-details
            v-model="route.total_time"
            style="border-radius: 10px; margin-top: 20px"
            color="#E2E2E2"
            background-color="#FFF"
            height="48px"
            disabled
            :error-messages="totalTimeError"
          />
        </v-col>
      </v-row>
      <v-row no-gutters align="center">
        <v-checkbox color="#144FA9" v-model="isReverseRoute" />
        <span class="newRouteText">Зворотній маршрут</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <img
              v-bind="attrs"
              v-on="on"
              src="@/assets/img/iconsSvg/helpIcon.svg"
              alt="hepl icon"
              style="margin-left: 8px"
            />
          </template>
          <span>Зворотній маршрут</span>
        </v-tooltip>
      </v-row>
    </div>
    <div class="sectionBackground">
      <p class="sectionTitle">Додаткові можливості</p>
      <div class="sectionLine" />
    </div>
    <div class="sectionBackground">
      <p class="sectionTitle">Детальний маршрут</p>
      <p class="sectionText">
        Додайте опис маршруту від місця відправлення до місця призначення
      </p>
      <div class="sectionLine" />
      <draggable v-model="route.cities" v-show="!showLoader">
        <transition-group>
          <city-field
            v-for="(city, index) in route.cities"
            :key="city.id"
            :city="city"
            :index="index"
            :stationList="stationList"
            @calculateArrivalFromOrigin="calculateArrivalFromOrigin"
            @deleteCityFromList="deleteCityFromList"
            style="margin-top: 20px"
          />
        </transition-group>
      </draggable>
      <add-new-item-btn
        text="Додати зупинку"
        style="margin-top: 20px"
        :disabled="$v.route.cities.$invalid"
        @click="addNewCity"
      />
    </div>
    <submit-button
      style="margin-top: 40px"
      :loading="isLoadingBtn"
      :disabled="$v.route.$invalid"
      @click="isEdit ? updateRoute() : createRoute()"
      >{{ isEdit ? "Зберегти" : "Створити" }}</submit-button
    >
    <v-row no-gutters justify="center">
      <cancel-btn
        text="Скасувати"
        style="margin-top: 10px"
        @click="$router.push({ name: 'routesComponent' })"
      />
    </v-row>
    <image-gallery-modal
      v-if="showImageGallery"
      :visible="showImageGallery"
      @chooseImage="chooseImage"
      type="Routes"
      @close="showImageGallery = false"
    />
  </v-col>
</template>
  
  <script>
import draggable from "vuedraggable";
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AddNewItemBtn from "../../UI/Buttons/addNewItemBtn.vue";
import CancelBtn from "../../UI/Buttons/cancelBtn.vue";
import requestFormData from "../../../requests/requestFormData";
import routesService from "./../../../requests/Admin/routesService.js";
import ImageGalleryModal from "../../UI/imageGalleryModal.vue";
import stationService from "../../../requests/Admin/stationService";
import cityService from "../../../requests/Admin/cityService";
import CityField from "./cityField.vue";
export default {
  mixins: [validationMixin],
  components: {
    SubmitButton,
    draggable,
    AddNewItemBtn,
    CancelBtn,
    ImageGalleryModal,
    CityField,
  },
  data: () => ({
    route: {
      image: {},
      departure: "",
      arrival: "",
      total_time: "",
      total_distance: "",
      cities: [],
    },
    showImageGallery: false,
    showLoader: false,
    isEdit: false,
    isReverseRoute: false,
    isLoadingBtn: false,
    key: 0,
    stationList: [],
    cityList: [],
    filteredFromCities: [],
    filteredToCities: [],
  }),
  validations: {
    route: {
      image: {
        required,
      },
      departure: {
        required,
      },
      destination: {
        required,
      },
      total_time: {
        required,
      },
      total_distance: {
        required,
      },
      cities: {
        $each: {
          station: {
            required,
          },
          arrival: {
            required,
          },
          time_from_start: {
            required,
          },
        },
      },
    },
  },
  mounted() {
    if (this.$route.name == "editRoute") {
      this.isEdit = true;
      this.getRoute();
    }
    this.getStations();
    this.getCities();
  },
  methods: {
    //CHECK IF INPUT VALUE IS NUMBER
    isNumber(evt) {
      const isNumber = isFinite(evt.key);
      if (!isNumber) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    chooseImage(img) {
      this.route.image = img;
      this.showImageGallery = false;
    },
    addNewCity() {
      this.route.cities.push({
        id: new Date().getTime(),
        city: "",
        address: "",
        arrival_time: "",
        time_from_start: "",
        station: "",
        station_info: {
          city: {
            alternative_name: "",
          },
        },
        is_detail: false,
        is_new: true,
      });
    },
    deleteCityFromList(id) {
      this.route.cities = this.route.cities.filter((city) => city.id !== id);
    },
    async getRoute() {
      await routesService.getRoute(this.$route.params.id).then((res) => {
        if (res.status == "Success") {
          this.setRouteForEdit(res.data);
        }
      });
    },
    async getStations() {
      await stationService.getAllStation().then((res) => {
        if (res.status == "Success") {
          this.stationList = res.data;
        }
      });
    },
    async getCities() {
      await cityService.getAllCity().then((res) => {
        if (res.status == "Success") {
          this.cityList = res.data;
          this.filteredFromCities = res.data;
          this.filteredToCities = res.data;
        }
      });
    },
    async createRoute() {
      this.$v.$touch();
      let isValidShedule = this.isValidShedule();
      if (!this.$v.$invalid && isValidShedule) {
        this.isLoadingBtn = true;
        let route_city = [];
        this.route.cities.forEach((route, index) => {
          route_city.push({
            city_uuid: route.city_id,
            station_uuid: route.station,
            address: route.address,
            arrival: route.arrival,
            time_from_start: route.time_from_start,
            time_from_previous: route.time_from_previous,
            stop_time: 0,
            platform: "10",
            type: route.type,
            sequence: index,
            translations: [],
          });
        });
        let data = {
          departure: this.route.departure,
          destination: this.route.destination,
          total_time: this.route.total_time,
          total_distance: this.route.total_distance,
          image_id: this.route.image.id,
          status: "Active",
          details: "test",
          descriptions: "test",
          cities: route_city,
        };
        let form = await requestFormData.jsonToFormData(data);
        await routesService.createRoute(form).then((res) => {
          if (res.status == "Success" && !this.isReverseRoute) {
            this.$router.push({ name: "routesComponent" });
          }
        });
        if (this.isReverseRoute) {
          let route_city_reverse = [];
          route_city = route_city.reverse();
          route_city.forEach((route, index) => {
            route_city_reverse.push({
              city_uuid: route.city_uuid,
              station_uuid: route.station_uuid,
              address: route.address,
              arrival: route.arrival,
              time_from_start: route.time_from_start,
              time_from_previous: route.time_from_previous,
              stop_time: 0,
              platform: "10",
              type: route.type,
              sequence: index,
              translations: [],
            });
          });
          let data = {
            departure: this.route.destination,
            destination: this.route.departure,
            total_time: this.route.total_time,
            total_distance: this.route.total_distance,
            image_id: this.route.image.id,
            status: "Active",
            details: "test",
            descriptions: "test",
            cities: route_city_reverse,
          };
          let form = await requestFormData.jsonToFormData(data);
          await routesService.createRoute(form).then((res) => {
            if (res.status == "Success") {
              this.$router.push({ name: "routesComponent" });
            }
          });
        }
      }
    },
    async updateRoute() {
      this.$v.$touch();
      let isValidShedule = this.isValidShedule();
      if (!this.$v.$invalid && isValidShedule) {
        this.isLoadingBtn = true;
        let route_city = [];
        this.route.cities.forEach((route, index) => {
          route_city.push({
            id: route.is_new ? "" : route.id,
            city_uuid: route.city_id,
            station_uuid: route.station,
            address: route.address,
            arrival: route.arrival,
            time_from_start: route.time_from_start,
            time_from_previous: route.time_from_previous,
            stop_time: 0,
            platform: "10",
            type: route.type,
            sequence: index,
            translations: [],
          });
        });
        route_city.forEach((city) => (city.id == "" ? delete city.id : ""));
        let data = {
          departure: this.route.departure,
          destination: this.route.destination,
          total_time: this.route.total_time,
          total_distance: this.route.total_distance,
          image_id: this.route.image.id,
          status: "Active",
          details: "test",
          descriptions: "test",
          cities: route_city,
        };
        let form = await requestFormData.jsonToFormData(data);
        await routesService.updateRoute(this.route.id, form).then((res) => {
          if (res.status == "Success" && !this.isReverseRoute) {
            this.$router.push({ name: "routesComponent" });
          }
        });
        if (this.isReverseRoute) {
          let route_city_reverse = [];
          route_city = route_city.reverse();
          route_city.forEach((route, index) => {
            route_city_reverse.push({
              id: route.is_new ? "" : route.id,
              city_uuid: route.city_uuid,
              station_uuid: route.station_uuid,
              address: route.address,
              arrival: route.arrival,
              time_from_start: route.time_from_start,
              time_from_previous: route.time_from_previous,
              stop_time: 0,
              platform: "10",
              type: route.type,
              sequence: index,
              translations: [],
            });
          });
          route_city_reverse.forEach((city) =>
            city.id == "" ? delete city.id : ""
          );
          let data = {
            departure: this.route.destination,
            destination: this.route.departure,
            total_time: this.route.total_time,
            total_distance: this.route.total_distance,
            image_id: this.route.image.id,
            status: "Active",
            details: "test",
            descriptions: "test",
            cities: route_city_reverse,
          };
          let form = await requestFormData.jsonToFormData(data);
          await routesService.updateRoute(this.route.id, form).then((res) => {
            if (res.status == "Success") {
              this.$router.push({ name: "routesComponent" });
            }
          });
        }
      }
    },
    isValidShedule() {
      let isValidSheduleCity =
        this.route.cities.findIndex(
          (time) => time.city_id == this.route.departure
        ) == -1
          ? false
          : true;
      if (!isValidSheduleCity) {
        this.errorRoute.push("Потрібно додати кінцеву точку");
      }
      return isValidSheduleCity;
    },
    setCityType(_station) {
      return this.stations.filter((station) => station.id == _station)?.[0]
        ?.city?.country_iso == "UA"
        ? "Ukraine"
        : "Foreign";
    },
    calculateArrivalFromOrigin() {
      if (this.route.cities.length > 0) {
        const originTime = this.timeToMinutes(
          this.route.cities.filter(
            (time) => time?.city_id == this.route?.departure
          )?.[0]?.arrival
        );
        if (originTime !== undefined) {
          let previousArrival = originTime;
          let daysToAdd = 0;
          for (let i = 0; i < this.route.cities.length; i++) {
            const city = this.route.cities[i];
            if (city.arrival) {
              let arrival =
                this.timeToMinutes(city.arrival) + daysToAdd * 24 * 60;
              if (i > 0 && arrival < previousArrival) {
                daysToAdd++;
                arrival += 24 * 60;
              }
              city.time_from_start = arrival - originTime;
              previousArrival = arrival;
            } else {
              city.time_from_start = 0;
            }
          }
        }
        this.formatTime();
        this.existCities = true;
      }
    },
    timeToMinutes(time) {
      if (time !== undefined) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      }
    },
    formatTime() {
      let minutes =
        this.route.cities[this.route.cities.length - 1].time_from_start;
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      this.route.total_time = `${hours.toString().padStart(2, "0")} год. ${mins
        .toString()
        .padStart(2, "0")} хв.`;
    },
    setTime(index, time) {
      this.route.cities[index] = time;
    },
    setStation(index, station) {
      this.route.cities[index].station_info = this.stationList.filter(
        (city) => city.id == station
      )?.[0] || {
        city: {
          name: "",
        },
      };
    },
    setRouteForEdit(item) {
      this.$set(this.route, "id", item.id);
      this.$set(this.route, "departure", item.departure.id);
      this.$set(this.route, "destination", item.destination.id);
      this.$set(this.route, "total_distance", item.total_distance);
      this.$set(this.route, "image", item.image);
      this.$set(this.route, "total_time", item.total_time);
      item.cities.forEach((city) => {
        this.route.cities.push({
          id: city.id,
          city: city.station.city.alternative_name,
          city_id: city.station.city_uuid,
          arrival: city.arrival,
          time_from_start: city.time_from_start,
          station: city.station.id,
          station_info: city.station,
          type: city.type,
          sequence: city.sequence,
          is_detail: false,
        });
      });
      this.route.cities = this.route.cities.sort(
        (a, b) => a.sequence - b.sequence
      );
    },
  },
  computed: {
    departureError() {
      const errors = [];
      let field = this.$v.route.departure;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    desctinationError() {
      const errors = [];
      let field = this.$v.route.destination;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    totalDistanceError() {
      const errors = [];
      let field = this.$v.route.total_distance;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    totalTimeError() {
      const errors = [];
      let field = this.$v.route.total_time;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
  },
  watch: {
    "route.departure": {
      deep: true,
      handler() {
        if (this.route.departure == null) {
          this.filteredFromCities = this.cityList;
        } else {
          let cityIndex = this.cityList.findIndex(
            (city) => city.id == this.route.departure
          );
          this.filteredToCities = this.cityList.filter(
            (city) => city.country_iso !== this.cityList[cityIndex].country_iso
          );
          if (
            this.route.departure !== "" &&
            this.route.cities.findIndex(
              (time) => time.city_id == this.route.departure
            ) == -1
          ) {
            let station = this.stationList.filter(
              (station) => station?.city?.id == this.route.departure
            )?.[0];
            this.route.cities[0] = {
              id: new Date().getTime(),
              type:
                this.cityList.filter(
                  (city) => city.id == this.route.departure
                )?.[0].country_iso == "UA"
                  ? "Ukraine"
                  : "Foreign",
              city_id: this.route.departure,
              address: "",
              arrival: "",
              time_from_start: 0,
              station: station.id,
              station_info: station,
              is_detail: false,
              sequence: 0,
            };
          }
        }
      },
    },
  },
};
</script>
  
  <style scoped>
.imgBackground {
  display: grid;
  width: 320px;
  height: 120px;
  place-items: center;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  background: #e7edf6;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  user-select: none;
  cursor: pointer;
}
.newRouteText {
  color: #4b5262;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>