<template>
  <v-col class="px-0 py-0">
    <v-row no-gutters align="center">
      <v-col class="py-0" style="padding-right: 20px">
        <v-row no-gutters align="center">
          <v-col cols="5" style="padding-right: 20px">
            <v-autocomplete
              outlined
              dense
              placeholder="Виберіть зупинку"
              label="Зупинка"
              hide-details
              v-model="city.station"
              style="border-radius: 10px"
              color="#E2E2E2"
              background-color="#FFF"
              height="48px"
              :items="Object.values(stationList)"
              :item-text="
                (value) =>
                  `${
                    value.translations.find(
                      (translate) => translate.lang == 'ua'
                    ).name
                  }`
              "
              :item-value="'id'"
              @change="setStation(city.station)"
            />
          </v-col>
          <v-col cols="5" style="padding-right: 20px">
            <v-text-field
              outlined
              dense
              placeholder="Введіть тут.."
              label="Місто"
              hide-details
              v-model="city.station_info.city.alternative_name"
              style="border-radius: 10px"
              color="#E2E2E2"
              background-color="#FFF"
              height="48px"
              disabled
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              outlined
              dense
              placeholder="Введіть тут.."
              label="Час прибуття"
              hide-details
              v-model="city.arrival"
              style="border-radius: 10px"
              color="#E2E2E2"
              background-color="#FFF"
              height="48px"
              type="time"
              @change="$emit('calculateArrivalFromOrigin')"
            />
          </v-col>
        </v-row>
      </v-col>
      <div
        class="iconBackground"
        style="margin-right: 12px"
        @click="$emit('deleteCityFromList', city.id)"
      >
        <div class="deleteIcon" />
      </div>
      <div class="iconBackground">
        <div class="dragIcon" />
      </div>
    </v-row>
    <v-expand-transition>
      <v-row
        no-gutters
        align="center"
        v-if="is_detail"
        style="margin-top: 20px"
      >
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            placeholder="Введіть тут.."
            label="Час від початкової зупинки"
            hide-details
            v-model="city.time_from_start"
            style="border-radius: 10px"
            color="#E2E2E2"
            background-color="#FFF"
            height="48px"
          />
        </v-col>
      </v-row>
    </v-expand-transition>
    <v-btn
      width="100%"
      height="32px"
      class="detailBtn"
      @click="is_detail = !is_detail"
      >Детальніше
      <v-icon
        >{{ is_detail ? "mdi-chevron-up" : "mdi-chevron-down" }}
      </v-icon></v-btn
    >
  </v-col>
</template>

<script>
export default {
  data: () => ({
    is_detail: false,
  }),
  props: {
    city: {
      require: true,
    },
    stationList: {
      require: true,
    },
  },
  methods: {
    setStation(station_id) {
      let station = this.stationList.find(
        (station) => station.id == station_id
      );
      this.city.station_info = station;
      this.city.city_id = station.city_uuid;
      this.city.type =
        station?.city?.country_iso == "UA" ? "Ukraine" : "Foreign";
      this.$emit("calculateArrivalFromOrigin");
    },
  },
};
</script>

<style scoped>
.detailBtn {
  text-transform: none;
  color: #1b1b1b !important;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  background: rgba(20, 79, 169, 0.03) !important;
  margin-top: 20px;
}
</style>